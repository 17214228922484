import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SignupComponent } from './signup/signup.component';
import { AccordionModule } from 'primeng/accordion';
import { OffersCardComponent } from './offers-card/offers-card.component';
import { OffersListComponent } from './offers-list/offers-list.component';
import { CardSwitchModule } from './card-switch/card-switch.module';
import { PvDrawerModule } from '@pv-frontend/pv-shared-components/drawer';
import { DetailsModalComponent } from './details-modal/details-modal.component';
import { SvgIconModule } from '@pv-frontend/pv-shared-components/svg-icon';
import { PvCopyModule } from '@pv-frontend/pv-shared-components/copy';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    HeaderComponent,
    SignupComponent,
    FooterComponent,
    OffersCardComponent,
    OffersListComponent,
    DetailsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AccordionModule,
    CardSwitchModule,
    PvDrawerModule,
    SvgIconModule,
    PvCopyModule,
    SkeletonModule
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Components
    HeaderComponent,
    SignupComponent,
    FooterComponent,
    OffersCardComponent,
    OffersListComponent,
    CardSwitchModule,
    PvDrawerModule,
    DetailsModalComponent,
    SvgIconModule,
    PvCopyModule
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }
