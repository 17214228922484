import { Component, OnInit, Inject,HostListener,DoCheck } from '@angular/core';
import { ApiService } from '../../pages/common/api.service';
import { Router} from '@angular/router';
import { GlobalConstants } from '../../pages/common/global-variables';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  providers: [GlobalConstants]
})
export class HeaderComponent implements OnInit, DoCheck {
  public deviceInfo = null;
  public isLoggedIn: boolean;
  public environment_type: string;
  public innerPage = false;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    public GlobalConstants: GlobalConstants,
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isLoggedIn = false;  
    this.environment_type  = environment.production ? "prod" : "sandbox";
  }

  public redirect(): void {
    if(this.isLoggedIn){
      this.router.navigate(['/offer']);
    } else {
      this.router.navigate(['/']);
    }
  }

  public ngOnInit(): void {
    const currentURL = new URL(window.location.href);
    const paramStep = currentURL.searchParams.get('step');
    if (paramStep !== 'verification') {
      this.checkAuth();
    }
  }

  private checkAuth(): void {
    if (localStorage.getItem("currentUser")) {
      this.isLoggedIn = true;
    } else {
      this.clearAuthStorage();
      this.isLoggedIn = false;
    }
  }

  private clearAuthStorage(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('thebin');
    sessionStorage.clear();
  }

  public reloadInit(): void {
    this.ngOnInit();
  }

  public ngDoCheck(): void {
    if (localStorage.getItem("currentUser")) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  public logout(): void {
    this.apiService.deleteToken()
      .subscribe({
        next: () => {
          this.clearSessionAndNavigate();
        },
        error: () => {
          this.clearSessionAndNavigate();
        }
      });
  }
  
  private clearSessionAndNavigate(): void {
    localStorage.clear();
    this.router.navigate(["/"]);
  }

}
