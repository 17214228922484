import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CardSwitchComponent } from "./card-switch.component";
import { PvDrawerModule } from '@pv-frontend/pv-shared-components/drawer';

@NgModule({
    declarations:[CardSwitchComponent],
    imports:[CommonModule,PvDrawerModule],
    exports:[CardSwitchComponent]
})
export class CardSwitchModule{
    
}