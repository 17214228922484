import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  },
  {
    path: 'offer',
    loadChildren: () => import("./pages/offerpage/offerpage.module").then(m => m.OfferpageModule)
  },
  {
    path: 'info',
    loadChildren: () => import("./components/info/info.module").then(m => m.InfoModule)
  },
  { 
    path: "**", pathMatch: "full", loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }