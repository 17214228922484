import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private titleService: Title) { 
    this.titleService.setTitle(environment?.projectTitle);
    this.loadGoogleAnalytics(environment?.gTagTrackingId);
  }

  private loadGoogleAnalytics(trackingID: string): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${ trackingID }`);
    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

    document?.documentElement?.firstChild?.appendChild(gaScript);
    document?.documentElement?.firstChild?.appendChild(gaScript2);
  }
}
