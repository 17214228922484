import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        'X-Client-Id': environment.clientId,
        'X-Project-Id': environment.projectId,
      }
    });
    let currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      if (currentUser.token) {
        request = request.clone({
          setHeaders: {
            'Authorization': "Bearer " + `${currentUser.token}`
          }
        });
      }
    }
    const currentCardId = JSON.parse(
      localStorage.getItem('userCardId') ?? 'null'
    );
    if (currentCardId) {
      const cardTokenMap = JSON.parse(
        localStorage.getItem('cardTokenMap') ?? '{}'
      );
      const currentCardToken = cardTokenMap[currentCardId] ?? null;
      if (currentCardToken) {
        request = request.clone({
          setHeaders: {
            'X-Card-Token': currentCardToken,
          },
        });
      }
    }
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          // console.log(response);
        },
        (error: HttpErrorResponse) => {
          if (error["status"] == 401){
            this.logout();
          }
          else if (error["status"] != 200 && error["status"] != 401 && error["status"] != 404) {
            // this.router.navigate(["error"]);
          }
        }
      )
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/"]);
  }
}
