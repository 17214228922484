import { Component  } from '@angular/core';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/environment.interface';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
  public currentYear: number = new Date().getFullYear();  
  public env:Environment = environment;
}
