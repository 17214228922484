import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalConstants {
  client: any;
  data: any;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router) {
    this.client = 'visa-contactless';
    this.data = {
      logo: {
        url: './assets/visalogo.png',
        footerurl: './assets/visalogo.png',
        desktop_url: './assets/visalogo.png',
        modal_url: './assets/visalogo.png',
        head_logo_height: '21',
        coupon_modal_logo: './assets/visalogo.png',
      },
      assets: {
        img: {
          banner: [],
        },
        client_css: 'citi.scss',
        manifest: 'manifest.webmanifest',
      },
      colors: {
        header_bg_color: '#fff',
        coupon_modal_bg_color: '#ffffff',
        offer_title_color: '#195993',
        main_text_color: '#262626',
        medium_text_color: '#5C5C5C',
        light_text_color: '#9D9D9D',
        main_bg_color: '#F4F4F4',
        active_card_bg_color: '#F4F4F4',
        seconday_bg_color: '#FDFCFA',
        mainBtn_color: '#FF3E3E',
        inactive_btn_color: '#D8D8D8',
        btn_positive_action_color: '#195993',
        active_text_color: '#EB483F',
      },
      legal_name: 'Visa Benefits',
      font_family: {
        link: 'https://images.poshvine.com/fonts/gotham-new/gotham-new.css',
        name: 'Gotham',
      },
      x_header: {
        prod: {
          client_id: 'b191c165-ffeb-4d55-81b9-6278b3c08bc5',
          main_project: '5c614407-968f-4e8f-8ff5-bbef5d4222b6',
          sub_project: 'a6a55986-d69d-4b91-ab5f-4168c787c9ab',
        },
        sandbox: {
          client_id: '09fc67f1-b0f7-486e-9ef4-54cb6b74e89f',
          main_project: '532792e1-c583-4437-b6e0-a5827597568b',
          sub_project: 'a6a55986-d69d-4b91-ab5f-4168c787c9ab',
        },
        default_card_type: 'credit',
      },
      search_type: 2,
      desktop_category_count: 6,
      card_num_type: 'first4_last4',
      card_type: 'Debit/Credit',
      tabs_type: 'category',
      tabs_type_mobile: 'category',
      has_exclusive: false,
      banner_type: 'static',
      login_button_needed: true,
      loginAt: 'card',
      login_theme: 1,
      login_steps: ['mobile', 'otp', 'card'],
      auto_expiry: {
        time: '',
        enabled: false,
      },
      template: 1,
      header_type: 'default',
      show_saving_for_bundle_codes: {},
      download_app_link: {
        header: false,
        footer: false,
        android_link: '',
        apple_link: '',
      },
      login_modal_type: 'desktop-prev-header',
      supported_devices: ['web', 'mobile'],
      benefits: ['offers'],
      banner_style: 1,
      carouselInterval: -1,
      api_version: 'v2',
      personalized_config: {
        coupon_terms_link: false,
        switch_card: false,
        autocard_check: false,
        view_savings: true,
        external_otp: false,
        redemption_page_needed: true,
        saving_page: false,
        resend_allowed: true,
        add_new_card: true,
        footer_logo: false,
        force_auth: false,
        token_auth: false,
        desktop_search: true,
        mobile_search: true,
        auth: true,
        coupon_modal: true,
        coupon_show: true,
        amount_show: false,
        modal_redeem_button: true,
        mobile_home_back_button: true,
        show_card_used: false,
        show_bundle_used: false,
        allow_user_create: true,
        dedicated_banner: false,
        mobile_banner: true,
        authenticate_before_claim: true,
        disclaimer_popup: false,
        cancelable_auth: true,
        display_bundle_category_title: true,
        mobile_login: true,
        is_concierge: false,
        has_card: true,
        home_subcategory_dropdown: false,
        home_subcategory_bar: false,
        has_near_by: false,
        has_international: false,
        mobile_profile_dropdown: true,
        category_page: true,
        authenticate_bin: true,
        datalayer: false,
        custom_gtm_tags: false,
        show_terms_before_redeem: false,
        details_desktop_tabs: false,
        show_special_category: false,
        send_project_id: true,
        show_card_applicability: false,
        card_applicability_fetch: 'api',
        show_card_select_on_auth: false,
        special_bundle: false,
        external_login_banner: false,
        has_international_offers: false,
        redemption_page_display_card_number: true,
        has_add_card_text: false,
        send_as_gift: false,
        applicableTag: false,
        has_maps: false,
      },
      subProjectLinks: ['paytm-wellnessoffers'],
      content: {
        bin_error: {
          first4_last4:
            'Dear customer, please re-check the first 4 and last 4 digits of the card number you have entered',
          first6:
            'Dear customer, please re-check the first 6 digits of your card number you have entered',
        },
        bin_invalid: {
          first4_last4:
            'Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com',
          first6: 'Invalid Card',
        },
      },
    };
  }
}
