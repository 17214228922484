import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError} from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { UserCardsResponse, CardDetails, SpecialBundleResponse, VerifyOtpResponse, } from '../../components/offers.interface'
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public unauthorizedEventEmiiter$ = new Subject<boolean>();
  constructor(
    private http: HttpClient,
  ) {}

 
  public saveCardDetailsToStorage(
    cardDetails: {
      expiryMM: string;
      expiryYY: string;
    },
    cardNumber: string,
    id?: string
  ) {
  
    this.saveInLocalStorage('cardDetails', {
      cardNumber: cardNumber,
      expiryMonth: cardDetails?.expiryMM,
      expiryYear: cardDetails?.expiryYY
    });
  }



  public initiate3dsPayment() {

    const cardDetails: CardDetails = this.getFromLocalStorage('cardDetails') as CardDetails ?? {};
    if (!cardDetails?.cardNumber || !cardDetails?.expiryMonth || !cardDetails?.expiryYear) {
      return this.handleErrorState('Card details not found')
    }
    const payload = {
      number: cardDetails?.cardNumber,
      expiry_month: cardDetails?.expiryMonth,
      expiry_year: cardDetails?.expiryYear,
    };
    return this.http
      .post(environment.corePathV2 + '/card_secure/login', payload)
      .pipe(
        map((res: any) => res),
        catchError((error) => this.handleErrorState(error))
      );
  }

  private handleErrorState(error: any) {
    if (
      error.status === 401 &&
      Array.isArray(error?.error?.errors) &&
      error.error.errors.length &&
      error.error.errors[0] === 'Card not verified'
    ) {
      console.error('Card not verified, please check');
    } else if (error.status === 401) {
      this.clearStorage();
      this.unauthorizedEventEmiiter$.next(true);
    }
    return throwError(() => error);
  }
  
  public saveInLocalStorage(key: string, value: string | object): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getFromLocalStorage<T>(key: string): T | null {
    const val = localStorage.getItem(key);
    if (val !== null) {
      return JSON.parse(val);
    }

    return null;
  }

  public clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

	public getCards(params = {}) {
		return this.http.get<UserCardsResponse>(environment.corePathV2 + '/user/user_cards', {
			params: params
		})
			.pipe(
				map((res:UserCardsResponse) => res || []),
				catchError(error => throwError(error))
			);
	}

  validateCard(bin:string, bin_type:string) {
    return this.http.get<any>(environment.corePathV2 + "/user/user_cards/search?bin=" + bin + "&bin_type=" + bin_type).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }

  public generateOtp(isSignUp:boolean, mobile: string) {
    let payload: { card_num?: string, mobile: string };
    let url: string;
    if(isSignUp){
      const cardDetails:string = this.getFromLocalStorage<CardDetails>('cardDetails')?.cardNumber ?? '';
      payload = {
        card_num : cardDetails,
        mobile
      }
      url = environment.corePathV2 + `/auth/init`;
    } else {
      payload = {
        mobile
      }
      url = environment.corePathV2 + `/auth/login`;
    }
    return this.http
      .post(url, payload)
      .pipe(
        map((res: {
            user_id?: string, 
            token?: string, 
          }) => res),
        catchError(
          (error: {
            error: {
              errors: string[];
              wrong_attempts_left: number;
              user_unlock_at: Date | string;
              next_otp_at: Date | string;
            };
          }) => this.handleErrorState(error)
        )
      );
  }

  public generateLoginOtp(mobile: number) {
    const payload = {
      mobile
    }
    return this.http
      .post(environment.corePathV2 + `/auth/login`, payload)
      .pipe(
        map((res: {
            user_id?: string, 
            token?: string, 
          }) => res),
        catchError(
          (error: {
            error: {
              errors: string[];
              wrong_attempts_left: number;
              user_unlock_at: Date | string;
              next_otp_at: Date | string;
            };
          }) => this.handleErrorState(error)
        )
      );
  }

  public verifyOtp(
    userId: string,
    payload: { code: string; otp_type?: string }
  ) {
    return this.http
      .post(
        environment.corePathV2 + `/users/${userId}/otps/verify`,
        payload
      )
      .pipe(
        map(
          (res: VerifyOtpResponse ) => res
        ),
        catchError(
          (error: {
            error: {
              errors: string[];
            };
          }) => this.handleErrorState(error)
        )
      );
  }

  public verifyTransaction(transactionId?: string) {
    return this.http
      .post(environment.corePathV2 + '/card_secure/verify', {
        id: transactionId,
      })
      .pipe(
        map((res: any) => res),
        catchError((error) => this.handleErrorState(error))
      );
  }


  public isAuthenticated(): boolean {
    return !!this.getFromLocalStorage('currentUser');
  }


  public getOfferList() {
    const params = { 'fetch_voucher_claimed': true }
    return this.http.get(environment.offerPathV2 + `/special_bundles`, { params: params }).pipe(
      map((res: SpecialBundleResponse ) => res),
      catchError((error) => this.handleErrorState(error))
    );
  }

  public getSpecialBundlesById(id: string) {
    return this.http.get(environment.offerPathV2 + `/special_bundles/${id}`).pipe(
      map((res:SpecialBundleResponse) => res || []),
      catchError(error => throwError(error))
    );
  }

  getOffersByMerchant(merchantId: string) {
    const params = {};
    return this.http
      .get(environment.apiUrlPsV1 + 'offers?merchant_id=' + merchantId + (this.isAuthenticated() ? '&elgble=true' : ''), { params: params })
      .toPromise();
  }


  redeem(id: string) {
    return this.http.post(environment.apiUrlPsV1 + `special_bundles/redeem_offer?id=${id}`, {}).pipe(
        map((res:SpecialBundleResponse) => res || []),
        catchError(error => throwError(error))
      );
  }

  getRedemptions(params = {}) {
    return this.http.get(environment.apiUrlPsV1 + "redemptions?", { params: params });
  }
  public deleteToken() {
    return this.http.post(environment.corePathV2 + '/sessions/' + 'delete', {})
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
  }
}

