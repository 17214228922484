<footer>
  <div class="bg-primary h-full">
    <div class="m-auto max-w-full flex flex-column justify-content-center">
      <div class="footer-container flex flex-row m-flex-column m-w-full my-5 justify-content-start align-items-baseline gap-1">
        <p class="flex align-content-center justify-content-center gap-2">Powered by <img [height]="22" alt="razorpay logo" src="https://images.poshvine.com/visa-contactless/razorpayLogo.svg"/></p>
        <p class="flex align-content-center justify-content-center align-items-center gap-2 text-xs flex-wrap desktop h-2rem">© {{currentYear}} 
         | <a class="underline" target=”_blank” href="/info/privacy-policy">Privacy Policy</a>
         | <a class="underline" target=”_blank” href="/info/terms-and-conditions">Terms & Conditions</a>
         | <a class="underline" target=”_blank” rel="noopener noreferrer" [href]="env?.faqLink">FAQ</a>
         | Contact us : <span>{{ env?.emailId }}</span>
         | <img [width]="34" [height]="34" alt="pcidss logo" src="https://images.poshvine.com/visa-contactless/pcidss.png"/>
        </p>
        <p class="flex align-content-center justify-content-center align-items-center gap-2 text-xs flex-wrap mobile h-2rem">© 2024 
         | <a class="underline" target=”_blank” href="/info/privacy-policy">Privacy Policy</a>
         | <a class="underline" target=”_blank” href="/info/terms-and-conditions">Terms & Conditions</a>
         | <a class="underline" target=”_blank” rel="noopener noreferrer" [href]="env?.faqLink">FAQ</a>
        </p>
        <p class="flex align-content-center justify-content-center align-items-center gap-2 text-xs flex-wrap mobile h-2rem">
          Contact us : <span>{{ env?.emailId }}</span>
         | <img [width]="34" [height]="34" alt="pcidss logo" src="https://images.poshvine.com/visa-contactless/pcidss.png"/>
        </p>
      </div>
    </div>
  </div>
</footer>